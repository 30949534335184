/* eslint-disable react/style-prop-object */
import React from "react";

export const Bolt: React.FC = () => (
  <svg width="10" height="15" viewBox="0 0 10 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10 5.9022H6.05303L7.61905 0L0 9.0978H3.94697L2.38095 15L10 5.9022Z"
      fill="url(#paint0_linear)"
    />
    <defs>
      <linearGradient
        id="paint0_linear"
        x1="5"
        y1="0"
        x2="5"
        y2="15"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#4FFADE" />
        <stop offset="1" stopColor="#5AC9F8" />
      </linearGradient>
    </defs>
  </svg>
);
