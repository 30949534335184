import styled from "@emotion/styled";

import { IBoxStyles, withBoxStyles } from "~src/shared/helpers/sharedComponentProps";
import { IPipeTheme } from "~src/shared/theme/darkVariant";

export type IHorizontalRuleProps = {
  color?: keyof IPipeTheme["components"]["Divider"];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  style?: any;
} & IBoxStyles;

export const OldHorizontalRuleDontUse: React.FC<IHorizontalRuleProps> = styled.hr<IHorizontalRuleProps>`
  border: none;
  border-bottom: 1px solid ${(props) => props.theme.components.Divider[props.color ?? "default"]};
  ${(props) => withBoxStyles(props)};
`;
