import styled from "@emotion/styled";
import React, { MouseEvent, ReactNode } from "react";

import { Flex } from "~src/designSystem/layout/Flex";
import { LoadingDots } from "~src/designSystem/loading/LoadingDots";

interface IProps {
  size?: string;
  margin?: string;
  textAlign?: string;
  color?: string;
  lineHeight?: string;
  maxWidth?: string;
  height?: string;
  weight?: string;
  isLoading?: boolean;
  children?: ReactNode;
  style?: React.CSSProperties;
  onClick?: (event: MouseEvent<HTMLSpanElement>) => void;
}

/** @deprecated Use Span from Text.new */
export const ReallyOldTextDontTouchAtAll = (props: IProps): React.ReactElement => {
  const { isLoading, height = "22px" } = props;

  if (isLoading === true) {
    return (
      <Flex
        direction="column"
        align="center"
        css={{ height, marginLeft: "2px", width: "fit-content" }}
      >
        <LoadingDots size={16} />
      </Flex>
    );
  }

  return <StyledSpan {...props}>{props.children}</StyledSpan>;
};

const StyledSpan = styled("span")<IProps>`
  max-width: ${(props) => props.maxWidth};
  margin: ${(props) => props.margin};

  text-align: ${(props) => props.textAlign};
  font-size: ${(props) => props.size};
  font-weight: ${(props) => props.weight ?? "400"};

  color: ${(props) => props.color ?? props.theme.components.Text.title};
  line-height: ${(props) => props.lineHeight};

  ${(props) => (props.onClick !== undefined ? "cursor: pointer" : undefined)}
`;
