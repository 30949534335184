import styled from "@emotion/styled";
import * as React from "react";

interface IProps {
  isOpen: boolean;
  direction?: "top" | "left" | "right";
  children?: React.ReactNode;
}

export const Drawer: React.FC<IProps> = (props: IProps) => {
  const { isOpen, children } = props;

  return <DrawerContainer {...props}>{isOpen ? children : null}</DrawerContainer>;
};

const DrawerContainer = styled("div")<IProps>`
  visibility: ${(props) => (props.isOpen ? "visible" : "hidden")};
  position: relative;
  top: ${(props) => (props.isOpen ? "0" : "-100%")};
  left: 0;

  transition: all 5s cubic-bezier(0.77, 0, 0.175, 1);

  /* z-index: 1000; */

  width: 100%;
  height: fit-content;
  background: white;
  border-radius: 7px;
`;
