/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

// Must match types from dynamic loading function in node_modules/next/dist/next-server/lib/dynamic.d.ts
interface IProps {
  error?: Error | null;
  isLoading?: boolean;
  pastDelay?: boolean;
}

/**
 * Placeholder to reserve the space during load.
 */
export const PayoutSuccessImgPlaceholder: React.FC<IProps> = () => {
  return (
    <div
      css={css`
        height: 250px;
        width: 250px;
      `}
    />
  );
};
