import stringify from "safe-stable-stringify";
import useSWR, { Fetcher, mutate, SWRConfiguration, SWRResponse } from "swr";
import { ScopedMutator } from "swr/dist/types";

import { LegacyRPCError } from "./rpc/rpcError";
import { INetworkRequest, IOutput, IReqHeaderOptions } from "./types";
import { callRequest } from "./useRequest";

/** Pre-configured useSWR hook for easy usage with Pipe API */
export const useNextRequest = <I, OutputData>(
  req: INetworkRequest<I, OutputData>,
  httpOptions?: IReqHeaderOptions,
  swrOptions?: SWRConfiguration,
): SWRResponse<IOutput<OutputData>, LegacyRPCError> => {
  const key = stringify(req);
  const fetcher: Fetcher<IOutput<OutputData>> = () => callRequest(req, httpOptions);
  return useSWR<IOutput<OutputData>, LegacyRPCError>(key, fetcher, swrOptions);
};

/**
 * Pre-configured mutate function from swr that stringifies a INetworkRequest to match the useNextRequest key.
 * Used to revalidate data.
 */
export const nextMutate = async <I, OutputData>(
  req: INetworkRequest<I, OutputData>,
  data?: OutputData,
  shouldRevalidate?: boolean | undefined,
): Promise<OutputData | undefined> =>
  (mutate as ScopedMutator<OutputData>)(stringify(req), data, shouldRevalidate);
