import { permission_enum_enum } from "~src/__generated__/graphql/types";
import PermissionDeniedPage from "~src/pages/401";

import { useHasPermission } from "./useHasPermission";

type IProps = {
  permission: permission_enum_enum;
  children: React.ReactElement;
};

/**
 * The Permissioned component restricts access to a page component (children) unless the
 * accessing user has the passed-in permission. If the permission is not present in the
 * user, the PermissionDenied 401 page is returned instead of the child component.
 */
export const Permissioned: React.FC<IProps> = ({ permission, children }) => {
  const hasPermission = useHasPermission(permission);

  if (!hasPermission) {
    return <PermissionDeniedPage />;
  }

  return children;
};
