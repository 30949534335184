import styled from "@emotion/styled";
import React, { SVGProps } from "react";

import { Avatar } from "~src/designSystem/atoms/Avatar";
import { OldCompanyLogoDontUse } from "~src/designSystem/deprecated/CompanyLogo";
import { getDomainFromEmail, isEmailDomainBlocked } from "~src/shared/helpers";
import { stringToBool } from "~src/shared/helpers/booleanCoercion";

type IProps = {
  name: string;
  email?: string;
  logo?: string;
  /**
   * Logo SVG if applicable
   */
  svg?: React.FC<SVGProps<SVGSVGElement>> | null;
  className?: string;
};

/**
 * Returns the domain if the email is a business email (non-blocked).
 * Otherwise, returns the full email address.
 */
const getNameFromEmail = (email?: string) => {
  if (stringToBool(email)) {
    const domain = getDomainFromEmail(email);
    return isEmailDomainBlocked(email) ? email : domain;
  }
  return "";
};

export const CompanyWithLogo: React.FC<IProps> = (props: IProps) => {
  const displayName = props.name.length > 0 ? props.name : getNameFromEmail(props.email);
  const SVGLogo = props.svg;
  return (
    <Wrapper className={props.className}>
      {SVGLogo !== undefined && SVGLogo !== null ? (
        <Avatar>
          <SVGLogo width="100%" height="100%" />
        </Avatar>
      ) : (
        <OldCompanyLogoDontUse name={displayName} email={props.email} logo={props.logo} />
      )}
      <span>{displayName}</span>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  pointer-events: none;
  display: grid;
  grid-template-columns: 25px 1fr;
  grid-column-gap: 13px;
  align-items: center;
  img {
    background-color: #fff;
  }
  span {
    white-space: nowrap;
  }
`;
