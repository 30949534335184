import { IoMdArrowDropup } from "react-icons/io";

import { Flexbox } from "~src/designSystem/layout/Flexbox";
import { LoadingDots } from "~src/designSystem/loading/LoadingDots";
import { formatBidPrice } from "~src/shared/helpers/format";
import { useSupportedCurrency } from "~src/vendor/utils/useSupportedCurrencies";

interface IProps {
  /** Bid price value in cents */
  value: number | undefined;
}

export const BidPrice: React.FC<IProps> = (props) => {
  const currency = useSupportedCurrency();
  const { value } = props;

  return (
    <Flexbox row alignItems="center">
      {value !== undefined ? (
        <>
          {formatBidPrice(value, currency)} <IoMdArrowDropup color="#27BE79" />
        </>
      ) : (
        <LoadingDots />
      )}
    </Flexbox>
  );
};
