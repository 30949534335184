import { useUser } from "~src/shared/auth/useUser";

export const useSupportedCurrencies = (): string[] => {
  const user = useUser();
  return user.vendor.supportedCurrencies;
};

// NB: this only works in a single currency per vendor world!
export const useSupportedCurrency = (): string => {
  const currencies = useSupportedCurrencies();
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  return currencies[0]!;
};
