import { useNextRequest } from "~src/shared/requests/hooks";
import { vendorRequests } from "~src/vendor/requests";

/**
 * Gets the remaining payout limit.
 */
export const useRemainingPayoutLimit = (): number => {
  const { data: overviewData } = useNextRequest(vendorRequests.getSubscriptionsOverview({}));

  const creditLimit = overviewData?.data?.creditLimit ?? 0;
  const creditUsage = overviewData?.data?.creditUsage ?? 0;

  return creditLimit - creditUsage;
};
