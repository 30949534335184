import styled from "@emotion/styled";
import React from "react";

import { BlueprintIcon } from "~src/designSystem/deprecated/BlueprintIcon";

export const ErrorIcon: React.FC = () => (
  <Wrapper>
    <IconWrapper>
      <BlueprintIcon icon="error" />
    </IconWrapper>
  </Wrapper>
);

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
`;

const IconWrapper = styled.div`
  line-height: 0;
  color: ${(props) => props.theme.components.Error.icon};
`;
