import { permission_enum_enum } from "~src/__generated__/graphql/types";
import { useUser } from "~src/shared/auth/useUser";

/**
 * This hook returns whether the logged-in user has the passed-in permission.
 */
export const useHasPermission = (permission: permission_enum_enum): boolean => {
  const user = useUser();
  return user.permissions.includes(permission);
};

/**
 * This hook returns whether the logged-in user has one of multiple permissions.
 */
export const useHasAnyPermission = (permissions: permission_enum_enum[]): boolean => {
  const user = useUser();
  return permissions.some((x) => user.permissions.includes(x));
};
