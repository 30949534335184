import styled from "@emotion/styled";
import { useFocusRing } from "@react-aria/focus";
import { useNumberFormatter } from "@react-aria/i18n";
import { useSlider, useSliderThumb } from "@react-aria/slider";
import { mergeProps } from "@react-aria/utils";
import { VisuallyHidden } from "@react-aria/visually-hidden";
import { SliderState, useSliderState } from "@react-stately/slider";
import React, { MutableRefObject, useRef } from "react";

export type ISliderProps = {
  /**
   * The slider's minimum value.
   */
  "minValue": number;

  /**
   * The slider's maximum value.
   */
  "maxValue": number;

  /**
   * The slider's step value.
   *
   * @default 1
   */
  "step"?: number;

  /**
   * The element's unique identifier.
   */
  "id"?: string;

  /**
   * The content to display as the label.
   */
  "label"?: React.ReactNode;

  /**
   * Defines a string value that labels the current element.
   */
  "aria-label"?: string;

  /**
   * The current value (controlled).
   */
  "value": number;

  /**
   * Handler that is called when the value changes.
   */
  "onChange"?: (value: number) => void;

  /**
   * Fired when the slider stops moving, due to being let go.
   */
  "onChangeEnd"?: (value: number) => void;

  /**
   * Whether the whole Slider is disabled.
   *
   * @default false
   */
  "isDisabled"?: boolean;

  /**
   * Whether to show the current value of the slider.
   *
   * @default true
   */
  "showValue"?: boolean;

  /**
   * Where to show the label--above or below the slider.
   *
   * @default top
   */
  "labelLocation"?: "top" | "bottom";

  /**
   * Options for formatting the Slider's value.
   *
   * @default TODO
   */
  "numberFormatOptions"?: Intl.NumberFormatOptions;
};

/**
 * A Design System Slider component.
 *
 * We currently only support:
 *
 * - the horizontal orientation
 * - a single thumb
 * - controlled API
 *
 * Support for variants of the above can be added as needed.
 */
export const Slider: React.FC<ISliderProps> = (props) => {
  // Because we only support a single thumb right now, but react-aria supports multiple
  // thumbs, we wrap the props like so.
  //
  // This allows us to expose a single thumb API while internally using react-aria's
  // multi thumb API.
  const singleThumbProps = React.useMemo(
    () => ({
      ...props,
      value: [Math.min(props.maxValue, props.value)],
      onChange: (xs: number[]) => props.onChange?.(xs[0] as number),
      onChangeEnd: (xs: number[]) => props.onChangeEnd?.(xs[0] as number),
    }),
    [props],
  );

  const trackRef = React.useRef(null);
  const numberFormatter = useNumberFormatter(props.numberFormatOptions);
  const state = useSliderState({ ...singleThumbProps, numberFormatter });
  const { groupProps, trackProps, labelProps, outputProps } = useSlider(
    singleThumbProps,
    state,
    trackRef,
  );

  return (
    <Wrapper {...groupProps} flexReverse={props.labelLocation === "bottom"}>
      <SupportingText>
        {props.label !== undefined && <Label {...labelProps}>{props.label}</Label>}
        {props.showValue !== false && (
          <Output {...outputProps}>{state.getThumbValueLabel(0)}</Output>
        )}
      </SupportingText>
      <InteractiveTrackWrapper {...trackProps} ref={trackRef}>
        <FullTrack />
        <ActiveTrack
          isDisabled={props.isDisabled === true}
          selectedPercentage={state.getThumbPercent(0) * 100}
        />
        <Thumb isDisabled={props.isDisabled === true} index={0} state={state} trackRef={trackRef} />
      </InteractiveTrackWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div<{ flexReverse: boolean }>`
  position: relative;
  display: flex;
  flex-direction: ${(props) => (props.flexReverse ? "column-reverse" : "column")};
  touchaction: none;
  gap: ${(props) => props.theme.spacing.xxxs};
`;

const SupportingText = styled.div`
  display: flex;
`;

const Label = styled.label`
  color: ${(props) => props.theme.components.Slider.Label.text};
`;

const Output = styled.output`
  margin-left: auto;
  text-align: right;
  flex: 1 0 auto;
  color: ${(props) => props.theme.components.Slider.Value.text};
`;

const InteractiveTrackWrapper = styled.div`
  position: relative;
  height: 32px;
  width: 100%;
  cursor: pointer;
`;

const FullTrack = styled.div`
  position: absolute;
  top: 14px;
  height: 4px;
  width: 100%;
  border-radius: 100px;
  background-color: ${(props) => props.theme.components.Slider.Background.unselected};
`;

const ActiveTrack = styled.div<{
  isDisabled: boolean;
  selectedPercentage: number;
}>`
  position: absolute;
  top: 14px;
  height: 4px;
  width: ${(props) => props.selectedPercentage}%;
  border-radius: 100px 0 0 100px;
  background-color: ${(props) => {
    if (props.isDisabled) {
      return props.theme.components.Slider.Background.disabled;
    }
    return props.theme.components.Slider.Background.selected;
  }};
`;

type IThumbProps = {
  index: number;
  state: SliderState;
  trackRef: MutableRefObject<null>;
  isDisabled: boolean;
};

const Thumb: React.FC<IThumbProps> = (props) => {
  const { state, trackRef, index } = props;
  const inputRef = useRef(null);
  const { thumbProps, inputProps } = useSliderThumb({ index, trackRef, inputRef }, state);
  const { focusProps, isFocusVisible } = useFocusRing();

  return (
    <ThumbWrapper selectedPercentage={state.getThumbPercent(index) * 100}>
      <ThumbButton
        {...thumbProps}
        isDisabled={props.isDisabled}
        isFocusVisible={isFocusVisible}
        isDragging={state.isThumbDragging(index)}
      >
        <VisuallyHidden>
          <input ref={inputRef} {...mergeProps(inputProps, focusProps)} />
        </VisuallyHidden>
      </ThumbButton>
    </ThumbWrapper>
  );
};

const ThumbWrapper = styled.div<{ selectedPercentage: number }>`
  position: absolute;
  top: 6px;
  transform: translateX(-50%);
  left: ${(props) => props.selectedPercentage}%;
`;

const ThumbButton = styled.div<{
  isDisabled: boolean;
  isFocusVisible: boolean;
  isDragging: boolean;
}>`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: ${(props) => {
    if (props.isDisabled) {
      return props.theme.components.Slider.Thumb.disabled;
    }
    if (props.isFocusVisible) {
      return props.theme.components.Slider.Thumb.focused;
    }
    if (props.isDragging) {
      return props.theme.components.Slider.Thumb.selected;
    }
    return props.theme.components.Slider.Thumb.unselected;
  }};
`;
