import { ThemeProvider } from "@emotion/react";
import styled from "@emotion/styled";
import { useRouter } from "next/router";
import React from "react";

import { Text } from "~src/designSystem/atoms/Text";
import { Flexbox } from "~src/designSystem/layout/Flexbox";
import { Gridbox } from "~src/designSystem/layout/Gridbox";
import { Spacer } from "~src/designSystem/layout/Spacer";
import { IconText } from "~src/designSystem/molecules/IconText";
import { Head } from "~src/designSystem/sortLater/Head";
import { TABLET_MAX } from "~src/shared/styles/responsive";
import { themeDark } from "~src/shared/theme/darkVariant";

const PermissionDeniedPage: React.FC = () => {
  const router = useRouter();
  return (
    <ThemeProvider theme={themeDark}>
      <WrapperContainer gridTemplateColumns="auto auto">
        <Head title="Permission Denied | Pipe" />
        <Flexbox justifyContent="center" alignItems="center" pad width="600px">
          <Flexbox justifyContent="center" maxWidth="480px" pad>
            <Text type="display" color="contentEmphasis" size="900">
              Uh oh...
            </Text>
            <Spacer y="16px" />
            <Text type="light" color="contentSecondary" size="400">
              You do not have sufficient privileges to view this page.
            </Text>
            <Spacer y="32px" />
            <a onClick={() => router.back()}>
              <IconText iconName="arrow-left" iconSize={10}>
                Back
              </IconText>
            </a>
          </Flexbox>
        </Flexbox>
      </WrapperContainer>
    </ThemeProvider>
  );
};

export default PermissionDeniedPage;

const WrapperContainer = styled(Gridbox)`
  height: 100vh;
  background: #000000;
  @media (max-width: ${TABLET_MAX}) {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
  }
`;
