import { styled } from "~src/designSystem/theme";
import {
  backgroundColorVariants,
  borderColorVariants,
  borderRadiusVariants,
  borderStyleVariants,
  borderWidthVariants,
  contentColorVariants,
  marginVariants,
  paddingVariants,
  sizeVariants,
} from "~src/designSystem/theme/variants";
import { castToStitchesStoryComponent } from "~src/storybook/utils";

export const ViewVariants = [
  backgroundColorVariants,
  borderColorVariants,
  borderRadiusVariants,
  borderStyleVariants,
  borderWidthVariants,
  contentColorVariants,
  marginVariants,
  paddingVariants,
  sizeVariants,
] as const;

export const View = styled("div", ...ViewVariants);

export const StorybookView = castToStitchesStoryComponent(View);

export const StorybookViewArgs = {
  color: {
    description: "The content color. Uses content colors from the semantic palette.",
    control: { type: "select" },
    table: {
      category: "View Variants",
      defaultValue: { summary: "contentDefault" },
    },
  },
  bgColor: {
    description: "The background color. Uses background colors from the semantic palette.",
    control: { type: "select" },
    table: {
      category: "View Variants",
    },
  },
  width: {
    control: { type: "select" },
    table: {
      category: "View Variants",
      defaultValue: { summary: "auto" },
    },
  },
  maxWidth: {
    control: { type: "select" },
    table: {
      category: "View Variants",
    },
  },
  minWidth: {
    control: { type: "select" },
    table: {
      category: "View Variants",
    },
  },
  height: {
    control: { type: "select" },
    table: {
      category: "View Variants",
      defaultValue: { summary: "auto" },
    },
  },
  maxHeight: {
    control: { type: "select" },
    table: {
      category: "View Variants",
    },
  },
  minHeight: {
    control: { type: "select" },
    table: {
      category: "View Variants",
    },
  },
  borderColor: {
    control: { type: "select" },
    table: {
      category: "View Variants",
      defaultValue: { summary: "strokeDefault" },
    },
  },
  borderWidth: {
    control: { type: "select" },
    table: {
      category: "View Variants",
    },
  },
  borderStyle: {
    control: { type: "select" },
    table: {
      category: "View Variants",
      defaultValue: { summary: "solid" },
    },
  },
  borderRadius: {
    control: { type: "select" },
    table: {
      category: "View Variants",
      defaultValue: { summary: "0" },
    },
  },
  m: {
    description: "Margin on all four sides. The options are the space tokens.",
    control: { type: "select" },
    table: {
      category: "View Variants",
      defaultValue: { summary: "0" },
    },
  },
  mx: {
    description: "Margin on the x-axis. The options are the space tokens.",
    control: { type: "select" },
    table: {
      category: "View Variants",
      defaultValue: { summary: "0" },
    },
  },
  my: {
    description: "Margin on the y-axis. The options are the space tokens.",
    control: { type: "select" },
    table: {
      category: "View Variants",
      defaultValue: { summary: "0" },
    },
  },
  mt: {
    description: "Margin on the top side. The options are the space tokens.",
    control: { type: "select" },
    table: {
      category: "View Variants",
      defaultValue: { summary: "0" },
    },
  },
  mb: {
    description: "Margin on the bottom side. The options are the space tokens.",
    control: { type: "select" },
    table: {
      category: "View Variants",
      defaultValue: { summary: "0" },
    },
  },
  ml: {
    description: "Margin on the left side. The options are the space tokens.",
    control: { type: "select" },
    table: {
      category: "View Variants",
      defaultValue: { summary: "0" },
    },
  },
  mr: {
    description: "Margin on the right side. The options are the space tokens.",
    control: { type: "select" },
    table: {
      category: "View Variants",
      defaultValue: { summary: "0" },
    },
  },
  p: {
    description: "Padding on all four sides. The options are the space tokens.",
    control: { type: "select" },
    table: {
      category: "View Variants",
      defaultValue: { summary: "0" },
    },
  },
  px: {
    description: "Padding on the x-axis. The options are the space tokens.",
    control: { type: "select" },
    table: {
      category: "View Variants",
      defaultValue: { summary: "0" },
    },
  },
  py: {
    description: "Padding on the y-axis. The options are the space tokens.",
    control: { type: "select" },
    table: {
      category: "View Variants",
      defaultValue: { summary: "0" },
    },
  },
  pt: {
    description: "Padding on the top side. The options are the space tokens.",
    control: { type: "select" },
    table: {
      category: "View Variants",
      defaultValue: { summary: "0" },
    },
  },
  pb: {
    description: "Padding on the bottom side. The options are the space tokens.",
    control: { type: "select" },
    table: {
      category: "View Variants",
      defaultValue: { summary: "0" },
    },
  },
  pl: {
    description: "Padding on the left side. The options are the space tokens.",
    control: { type: "select" },
    table: {
      category: "View Variants",
      defaultValue: { summary: "0" },
    },
  },
  pr: {
    description: "Padding on the right side. The options are the space tokens.",
    control: { type: "select" },
    table: {
      category: "View Variants",
      defaultValue: { summary: "0" },
    },
  },
};
