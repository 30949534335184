/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import React from "react";
import { BsFillExclamationTriangleFill } from "react-icons/bs";

import { ErrorBoundary } from "~src/shared/errors/components/ErrorBoundary";
import { ErrorIcon } from "~src/shared/errors/components/ErrorIcon";
import { getDomainFromEmail, isEmailDomainBlocked, textToColor } from "~src/shared/helpers";
import { useSrcValidator } from "~src/shared/helpers/hooks";

import { Avatar, IAvatarBadgeColor, IAvatarProps } from "../../atoms/Avatar";
import { IImageOrSVG } from "../../sortLater/ImageOrSVG";

/** Requires email or name for icon fallback in case invalid logo. */
export interface ICompanyLogoProps extends IAvatarProps {
  /** Name of vendor */
  name?: string;
  logo?: IImageOrSVG;
  email?: string;
  className?: string;
  color?: IAvatarBadgeColor;
  style?: React.CSSProperties;
}

export const OldCompanyLogoDontUse: React.FC<ICompanyLogoProps> = (props) => (
  <ErrorBoundary fallback={<ErrorIcon />}>
    <Body {...props} />
  </ErrorBoundary>
);

const Body: React.FC<ICompanyLogoProps> = (props) => {
  const { logo, name, email, className, ...avatarProps } = props;

  const emailDomain = email !== undefined ? getDomainFromEmail(email) : undefined;
  const isNonBusinessEmail = email !== undefined && isEmailDomainBlocked(email);

  const iconInitials = (() => {
    switch (true) {
      case name !== undefined:
        return name?.charAt(0).toUpperCase();
      case isNonBusinessEmail:
        return email?.charAt(0).toUpperCase();
      default:
        return emailDomain?.charAt(0).toUpperCase();
    }
  })();

  const theLogo = (() => {
    if (logo !== undefined) {
      return logo;
    }
    if (emailDomain !== undefined && !isNonBusinessEmail) {
      return `https://logo.clearbit.com/${emailDomain}`;
    }
    return "";
  })();

  const isImageValid = useSrcValidator(typeof theLogo === "string" ? theLogo : undefined);

  if (!isImageValid && typeof theLogo === "string") {
    return (
      <Avatar
        {...avatarProps}
        text={name}
        className={className}
        css={css`
          background-color: ${textToColor(name ?? "P")};
        `}
      >
        {iconInitials ?? (
          <BsFillExclamationTriangleFill
            size={avatarProps.size !== undefined ? avatarProps.size * 0.55 : 16}
          />
        )}
      </Avatar>
    );
  }

  if (typeof theLogo === "string") {
    return <Avatar {...avatarProps} src={theLogo} />;
  }

  const TheLogo = theLogo;
  return (
    <Avatar {...avatarProps}>
      <TheLogo />
    </Avatar>
  );
};
